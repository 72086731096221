.main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
