
body{
    /* background: rgb(223, 232, 233);
    background: linear-gradient(135deg, rgb(223, 232, 233) 0%,); */
    font-family: "work sans";
  }

.photo {
    height: 100px;
    margin-bottom: 20%;
}

.username {
    text-align: left;
    color: black;
    padding: 2%;
}
.password {
    text-align: left;
    color: black;
    padding: 2%;

}
.loginform {
    margin-right: 50%;
    margin-bottom: 0%;

}
label{
   
   position:relative;
}
